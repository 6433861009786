import styled from 'styled-components'

export const HeaderLink = styled.a.attrs(() => ({
    className: 'Link',
}))`
    text-decoration: none;
    z-index: 2;
    color: #fff;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    @media screen and (max-width: 480px) {
        color: #fff;
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
`

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    z-index: 2;
    align-items: center;

    @media screen and (max-width: 480px) {
        margin-bottom: 2rem;
    }
`

export const Logo = styled.img.attrs(() => ({
    className: 'Logo',
}))`
    margin-top: 80px;
    margin-bottom: 40px;
    margin-left: 20px;
    width: 35rem;
    z-index: 2;
    cursor: pointer;

    @media screen and (max-width: 1024px) {
        width: 30rem;
    }

    @media screen and (max-width: 480px) {
        margin-top: 50px;
        width: 300px;
    }
`

export const LinksContainer = styled.div.attrs(() => ({
    className: 'LinksContainer',
}))`
    display: grid;
    grid-template-columns: repeat(6, 1fr); /* 4 columns for small screens */
    margin-right: auto;
    margin-left: auto;
    max-width: 1140px;
    gap: 80px;

    @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(3, 1fr); /* 4 columns for small screens */
        gap: 20px;
        justify-items: center; /* Center items horizontally within the grid */
    }

    @media screen and (max-width: 480px) {
        grid-template-columns: repeat(3, 1fr); /* 4 columns for small screens */
        gap: 20px;
        justify-items: center; /* Center items horizontally within the grid */
    }
`
