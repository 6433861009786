// @ts-nocheck

import React, { useRef, useState } from 'react'
import { Alert, Button, Card, Form } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'

export default function SignupContent(): JSX.Element {
    const emailRef = useRef<HTMLInputElement | null>(null)
    const passwordRef = useRef<HTMLInputElement | null>(null)
    const passwordConfirmRef = useRef<HTMLInputElement | null>(null)
    const [error, setError] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const navigate = useNavigate()

    async function handleSubmit(e: React.FormEvent): Promise<void> {
        e.preventDefault()

        if (passwordRef.current!.value !== passwordConfirmRef.current!.value) {
            return setError('A jelszavak nem egyeznek meg')
        }

        try {
            setError('')
            setLoading(true)
            //await signup(emailRef.current!.value, passwordRef.current!.value)
            navigate('/login')
        } catch {
            setError('Nem sikerült a regisztráció')
        }
        setLoading(false)
    }

    return (
        <>
            <Card bg="dark" text="white">
                <Card.Body>
                    <h2 className="text-center mb-4">Regisztráció</h2>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group id="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" ref={emailRef} required />
                        </Form.Group>
                        <Form.Group id="password">
                            <Form.Label>Jelszó</Form.Label>
                            <Form.Control type="password" ref={passwordRef} required />
                        </Form.Group>
                        <Form.Group id="password-confirm">
                            <Form.Label>Jelszó megerősítése</Form.Label>
                            <Form.Control type="password" ref={passwordConfirmRef} required />
                        </Form.Group>
                        <Button disabled={loading} className="w-100 mt-4" type="submit">
                            Regisztrálás
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
            <div className="w-100 text-center mt-2 text-white">
                Már van felhasználód? <Link to="/login">Jelentkezz be!</Link>
            </div>
        </>
    )
}
