import styled from 'styled-components'

export const ContentHolder = styled.div`
    background-color: #000000;
    color: #ffffff;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`

export const TableContainer = styled.div`
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
    z-index: 1;
    margin: auto;
    margin-top: 5rem;

    @media screen and (max-width: 480px) {
        width: 90%;
        margin: auto;
    }
`

export const IconContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: auto;
    z-index: 1;

    @media screen and (max-width: 480px) {
    }
`
