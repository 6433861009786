// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyCg8jdKc5_z3dNAgGDFoG9uUA56aMhezLo',
    authDomain: 'thomasrush-7b982.firebaseapp.com',
    projectId: 'thomasrush-7b982',
    storageBucket: 'thomasrush-7b982.appspot.com',
    messagingSenderId: '911072272353',
    appId: '1:911072272353:web:e041eec23ab604c48cfca0',
    measurementId: 'G-FSRK9KGPW0',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

export default app
