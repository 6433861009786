import { FC, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import { AiFillEdit } from 'react-icons/ai'
import { UpdateEvent } from 'services/event.service'

interface EditEventModalProps {
    ID: string
    date: string
    place: string
    link: string
    onEventUpdated: () => void
}

const EditEventModal: FC<EditEventModalProps> = ({ ID, date: initialDate, place: initialPlace, link: initialLink, onEventUpdated }) => {
    const [showModal, setShowModal] = useState(false)

    // Controlled inputs
    const [date, setDate] = useState(initialDate)
    const [place, setPlace] = useState(initialPlace)
    const [link, setLink] = useState(initialLink)

    const newEventString = 'Esemény szerkesztése'

    const handleClose = () => setShowModal(false)
    const handleShow = () => setShowModal(true)

    const submitForm = async () => {
        try {
            // Use the controlled input values
            await UpdateEvent({
                ID,
                date,
                place,
                link,
            })

            handleClose()
            onEventUpdated() // Update the event list
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <AiFillEdit style={{ cursor: 'pointer' }} onClick={handleShow} />
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{newEventString}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicDate">
                            <Form.Label>Dátum</Form.Label>
                            <Form.Control
                                type="date"
                                name="date"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                                placeholder="Add meg a dátumot"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPlace">
                            <Form.Label>Helyszín</Form.Label>
                            <Form.Control
                                type="input"
                                name="place"
                                value={place}
                                onChange={(e) => setPlace(e.target.value)}
                                placeholder="Add meg a helyszínt"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicLink">
                            <Form.Label>Link</Form.Label>
                            <Form.Control
                                type="input"
                                name="link"
                                value={link}
                                onChange={(e) => setLink(e.target.value)}
                                placeholder="Add meg az esemény linkjét"
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Bezárás
                    </Button>
                    <Button variant="primary" onClick={submitForm} type="submit">
                        Mentés
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default EditEventModal
