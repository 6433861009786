import { BackgroundImage } from 'components/BackgroundImage.style'
import { Header } from 'components/Header'
import contactImg from 'images/contanct/contact.webp'
// @ts-ignore
import mailIcon from 'images/contanct/mail_icon.png'
// @ts-ignore
import phoneIcon from 'images/contanct/phone_icon.png'
import { getRandomBg } from 'utils'

import { Container, Icon, ImageContainer, Text, TextBlock, TextContainer, TextWrapper, Wrapper } from './ContactContent.style'

export const ContactContent = () => {
    const email = 'thmsrsh@gmail.com'
    const phoneNumber = '+36 30 413 1991'

    const linkStyle = {
        textDecoration: 'underline',
        color: 'inherit', // Inherit text color from parent element
    }

    return (
        <Container>
            <BackgroundImage backgroundImage={getRandomBg()} />
            <Header />
            <Wrapper>
                <ImageContainer src={contactImg} />
                <TextContainer>
                    <TextWrapper>
                        <TextBlock>
                            <Icon src={mailIcon} alt="Icon 1 Alt Text" />
                            <Text>
                                <a href={`mailto:${email}`} style={linkStyle}>
                                    {email}
                                </a>
                            </Text>
                        </TextBlock>
                        <TextBlock>
                            <Icon src={phoneIcon} alt="Icon 2 Alt Text" />
                            <Text>
                                <a href={`tel:${phoneNumber}`} style={linkStyle}>
                                    {phoneNumber}
                                </a>
                            </Text>
                        </TextBlock>
                    </TextWrapper>
                </TextContainer>
            </Wrapper>
        </Container>
    )
}
