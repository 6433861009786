import http from './http-common'

export const PostEvent = (data: any) => {
    return http.post('/events', data).then((res) => {
        return res
    })
}

export const GetAllEvents = () => {
    return http.get('/events').then((res) => {
        return res
    })
}

export const GetEvent = (data: any) => {
    return http.get(`/events/${data.ID}`).then((res) => {
        return res
    })
}

export const DeleteEvent = (data: any) => {
    return http.delete(`/events/${data.ID}`).then((res) => {
        return res
    })
}

export const UpdateEvent = (data: any) => {
    return http.put(`/events/${data.ID}`, data).then((res) => {
        return res
    })
}
