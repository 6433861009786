import { BackgroundImage } from 'components/BackgroundImage.style'
import { Header } from 'components/Header'
import { getRandomBg } from 'utils'

import { Container } from './AdminContent.style'
import AdminTable from './AdminTable/AdminTable'

export const AdminContent = () => {
    return (
        <Container>
            <BackgroundImage backgroundImage={getRandomBg()} />
            <Header />
            <AdminTable />
        </Container>
    )
}
