import { BackgroundImage } from 'components/BackgroundImage.style'
import { Container } from 'react-bootstrap'
import { getRandomBg } from 'utils'

import { AuthContainer, ContentHolder } from './components/Auth.style'
import SignupContent from './components/SignupContent'

export function Signup() {
    return (
        <ContentHolder>
            <BackgroundImage backgroundImage={getRandomBg()} />
            <AuthContainer>
                <Container className="d-flex align-items-center justify-content-center">
                    <div className="w-100" style={{ maxWidth: '400px' }}>
                        <SignupContent />
                    </div>
                </Container>
            </AuthContainer>
        </ContentHolder>
    )
}
