// @ts-nocheck

import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from 'services/auth.service'

export const Private = () => {
    const { currentUser } = useAuth()

    return currentUser ? <Outlet /> : <Navigate to="/login" />
}
