import { DeleteEvent } from 'services/event.service'

import bg1 from './images/background/1AAE2A8E-2DD4-4A7C-B824-7474542B5DFB.webp'
import bg2 from './images/background/193426107_3891710214211177_1936083759723634824_n.webp'
import bg3 from './images/background/255347559_4370095513039309_4859015692204566041_n.webp'
import bg4 from './images/background/IMG_1146.webp'
import bg5 from './images/background/IMG_2546.webp'
import bg6 from './images/background/IMG_3986.webp'
import bg7 from './images/background/IMG_3987.webp'
import bg8 from './images/background/IMG_4690.webp'
import bg9 from './images/background/IMG_4879.webp'
import bg10 from './images/background/IMG_5064.webp'
import bg11 from './images/background/IMG_5237.webp'
import bg12 from './images/background/IMG_5238.webp'
import bg13 from './images/background/IMG_8164.webp'
import bg14 from './images/background/IMG_8713.webp'
import bg15 from './images/background/IMG_8714.webp'
import bg16 from './images/background/IMG_8851.webp'
import bg17 from './images/background/IMG_8855.webp'
import bg18 from './images/background/NikesSoos-2471.webp'
import bg19 from './images/background/NikesSoos-2492.webp'

const backgroundImages = [bg1, bg2, bg3, bg4, bg5, bg6, bg7, bg8, bg9, bg10, bg11, bg12, bg13, bg14, bg15, bg16, bg17, bg18, bg19]

export const getRandomBg = (): string => {
    const min: number = 0
    const max: number = backgroundImages.length - 1
    const randIndex: number = Math.floor(Math.random() * (max - min + 1)) + min

    return backgroundImages[randIndex]
}

export const autoDeleteEvents = async (events: any[], today: Date): Promise<any[]> => {
    const updatedEvents: any[] = []

    for (const event of events) {
        const eventDate: Date = new Date(event.date)

        if (
            (eventDate.getMonth() < today.getMonth() && eventDate.getFullYear() <= today.getFullYear()) ||
            eventDate.getFullYear() < today.getFullYear()
        ) {
            try {
                await DeleteEvent({ ID: event._id })
            } catch (error) {
                console.log(error)
            }
        } else {
            updatedEvents.push(event)
        }
    }

    return updatedEvents
}

export const monthNames: string[] = [
    'január',
    'február',
    'március',
    'április',
    'május',
    'június',
    'július',
    'augusztus',
    'szeptember',
    'október',
    'november',
    'december',
]

export const getMonthName = (month: number): string => {
    return monthNames[month]
}
