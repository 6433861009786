import { BackgroundImage } from 'components/BackgroundImage.style'
import { Header } from 'components/Header'
import merch1 from 'images/merch/merch1.webp'
import merch2 from 'images/merch/merch2.webp'
import { useNavigate } from 'react-router-dom'
import { getRandomBg } from 'utils'

import { Button, ButtonText, Container, ImageContainer, Text, TextContainer, Wrapper } from './MerchContent.style'

export const MerchContent = () => {
    const navigate = useNavigate()

    return (
        <Container>
            <BackgroundImage backgroundImage={getRandomBg()} />
            <Header />
            <Wrapper>
                <ImageContainer src={merch1} loading="lazy" />
                <ImageContainer src={merch2} loading="lazy" />
            </Wrapper>
            <TextContainer>
                <Text>Póló és pulóver rendelés esetén keress bátran email-en vagy telefonon!</Text>
            </TextContainer>
            <Wrapper>
                <Button>
                    <ButtonText onClick={() => navigate('/contact')}>Kapcsolat</ButtonText>
                </Button>
            </Wrapper>
        </Container>
    )
}
