import styled from 'styled-components'

export const ContentHolder = styled.div`
    background-color: #000000;
    width: 100%;
    min-height: 100vh;
    display: flex;
`

export const AuthContainer = styled.div`
    position: absolute;
    width: 100%;
    padding-top: 12%;
`
