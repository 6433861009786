import styled from 'styled-components'

type BackgroundImageProps = {
    backgroundImage?: string
}

export const Container = styled.div.attrs(() => ({
    className: 'ContentHolder',
}))<BackgroundImageProps>`
    background-color: #000000;
    color: #ffffff;
    width: 100%;
    min-height: 100vh;
`

export const Wrapper = styled.div.attrs(() => ({
    className: 'Wrapper',
}))<BackgroundImageProps>`
    display: flex;
    width: 612px;
    margin-top: 5rem;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        width: 100%;
    }
`

export const ImageContainer = styled.img<BackgroundImageProps>`
    position: relative;
    width: 306px;
    height: 381px;
    object-fit: cover;

    @media screen and (max-width: 1024px) {
        width: 30.6rem;
        height: 17.2rem;
        max-width: 85%;
        margin: auto;
    }
`

export const TextContainer = styled.div.attrs(() => ({
    className: 'TextContainer',
}))`
    /* Existing styles for the TextContainer */
    width: 100%;
    height: 381px;
    display: flex;
    flex-direction: column; /* Stack text vertically */
    align-items: center; /* Center horizontally */
    justify-content: flex-start; /* Align text to the top */
    margin: auto;
    background-color: #000;
    opacity: 0.8;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.8;
    }

    @media screen and (max-width: 1024px) {
        width: 30.6rem;
        max-width: 85%;
        height: auto;
        flex-shrink: 0;
    }
`

export const Icon = styled.img`
    margin-right: 15px;
    margin-top: -15px;
    opacity: 1;
    position: relative;
    width: 20px;

    @media screen and (max-width: 1024px) {
        width: 30px;
    }
`

export const TextBlock = styled.div`
    display: flex;
    align-items: center;

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        gap: 10px;
        margin-bottom: 30px;
    }
`

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 50px;
`

export const Text = styled.p.attrs(() => ({
    className: 'Text',
}))`
    color: #fff;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-top: 0; /* Remove vertical margin */
    z-index: 100; /* Ensure text is above the ::before pseudo-element */

    @media screen and (max-width: 480px) {
    }
`
