import { useEffect, useState } from 'react'
import { Alert, Button, Table } from 'react-bootstrap'
import { AiFillDelete } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'services/auth.service'
import { DeleteEvent, GetAllEvents } from 'services/event.service'
import { monthNames } from 'utils'

import { IconContainer, TableContainer } from './AdminTable.style'
import AddEventModal from './components/AddEventModal'
import EditEventModal from './components/EditEventModal'

interface Event {
    _id: string
    date: string
    place: string
    link: string
}

const formatDate = (date: string): string => {
    const d = new Date(date)
    const day = d.getDate().toString().padStart(2, '0')
    const month = monthNames[d.getMonth()]
    const year = d.getFullYear()
    return `${year}. ${month} ${day}.`
}

const AdminTable: React.FC = () => {
    const [events, setEvents] = useState<Event[]>([])
    const [eventsSorted, setEventsSorted] = useState<Event[]>([])
    const [error, setError] = useState<string>('')
    const { logOut } = useAuth()
    const navigate = useNavigate()

    const getEvents = async () => {
        try {
            const res = await GetAllEvents()
            setEvents(res.data)
        } catch (error) {
            setError('Failed to fetch events')
        }
    }

    const sortDates = () => {
        const sortedEvents = [...events]
        sortedEvents.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
        setEventsSorted(sortedEvents)
    }

    const handleDeleteEvent = async (eventId: string) => {
        try {
            await DeleteEvent({ ID: eventId })
            const updatedEvents = events.filter((event) => event._id !== eventId)
            setEvents(updatedEvents)
        } catch (error) {
            // Handle error gracefully
        }
    }

    useEffect(() => {
        getEvents()
    }, [])

    useEffect(() => {
        sortDates()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [events])

    return (
        <>
            <TableContainer>
                {error && <Alert variant="danger">{error}</Alert>}
                <div className="text-center mb-5">
                    <Button
                        variant="link"
                        onClick={async () => {
                            setError('')
                            try {
                                await logOut()
                                navigate('/login')
                            } catch {
                                setError('Sikertelen kijelentkezés')
                            }
                        }}
                    >
                        Kijelentkezés
                    </Button>
                </div>
                <Table responsive striped bordered hover variant="dark">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Dátum</th>
                            <th>Helyszín</th>
                            <th>Műveletek</th>
                        </tr>
                    </thead>
                    <tbody>
                        {eventsSorted.map((item, index) => (
                            <tr key={item._id}>
                                <td>{index + 1}</td>
                                <td>{formatDate(item.date)}</td>
                                <td>{item.place}</td>
                                <td>
                                    <IconContainer>
                                        <EditEventModal
                                            ID={item._id}
                                            date={new Date(item.date).toDateString()}
                                            place={item.place}
                                            link={item.link}
                                            onEventUpdated={getEvents}
                                        />
                                        <AiFillDelete style={{ cursor: 'pointer' }} onClick={() => handleDeleteEvent(item._id)} />
                                    </IconContainer>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <AddEventModal onEventAdded={getEvents} />
            </TableContainer>
        </>
    )
}

export default AdminTable
