import styled from 'styled-components'

export const Container = styled.div`
    background-color: #000000;
    color: #ffffff;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`
