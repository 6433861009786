import styled from 'styled-components'

export const EventsList = styled.div.attrs(() => ({
    className: 'EventsList',
}))`
    width: fit-content;
    width: fit-content;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 2rem;
    margin-top: 3rem;

    @media screen and (max-width: 768px) {
        max-width: 90%;
        width: fit-content;
    }
    @media screen and (max-width: 480px) {
        margin-top: 1rem;
    }
`

export const MonthText = styled.div.attrs(() => ({
    className: 'Month',
}))`
    color: #fff;
    font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-top: 40px;
    margin-bottom: 10px;

    @media screen and (max-width: 480px) {
        font-size: 18px;
        margin-top: 10px;
        margin-bottom: 5px;
    }
`

export const YearText = styled.div.attrs(() => ({
    className: 'Year',
}))`
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 32px;
    margin-top: 3rem;
    margin-bottom: -1rem;
    height: 2rem;
    z-index: 2;
    margin-left: -1rem;

    @media screen and (max-width: 480px) {
        margin-top: 1rem;
        margin-left: -0.5rem;
        margin-bottom: -5px;
        font-size: 20px;
    }
`

export const DateText = styled.div.attrs((props) => ({
    className: 'Date',
}))`
    color: #8afedb;
    font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-right: 1rem;

    @media screen and (max-width: 480px) {
        font-size: 18px;
        margin-right: 0.5rem;
    }
`

export const PlaceText = styled.div.attrs((props) => ({
    className: 'Place',
}))`
    color: #8afedb;
    font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    @media screen and (max-width: 480px) {
        font-size: 18px;
    }
`

export const Event = styled.div.attrs(() => ({
    className: 'Event',
}))`
    display: flex;
    position: relative;
    flex-direction: row;
    margin-right: auto;
    width: fit-content;
    z-index: 1000 !important;

    &:hover {
        cursor: pointer;
        -webkit-text-fill-color: #04b07c;

        @media screen and (max-width: 480px) {
            -webkit-text-fill-color: #04b07c;
        }
    }
`

export const TextContainer = styled.div.attrs(() => ({
    className: 'TextContainer',
}))`
    display: flex;
    margin: auto;
    justify-content: center;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 480px) {
    }
`

export const Text = styled.p.attrs(() => ({
    className: 'Text',
}))`
    font-family: 'Roboto';
    text-align: center;
    font-size: 1rem;

    @media screen and (max-width: 480px) {
        font-size: 0.9rem;
    }
`
