import { useNavigate } from 'react-router-dom'

// @ts-ignore
import LogoImg from '../../images/logo/Thomas_Rush_logo_white.png'
import { Container, HeaderLink, LinksContainer, Logo } from './Header.style'

export const Header = () => {
    const navigate = useNavigate()

    return (
        <Container>
            <Logo
                src={LogoImg}
                alt="phoneLogo"
                onClick={() => {
                    navigate(`/`)
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                }}
            />
            <LinksContainer>
                <HeaderLink
                    href="https://www.facebook.com/thomasrushofficial"
                    target="_blank" // Open in a new tab
                >
                    facebook
                </HeaderLink>
                <HeaderLink
                    href="https://www.instagram.com/thomasrushofficial/"
                    target="_blank" // Open in a new tab
                >
                    instagram
                </HeaderLink>
                <HeaderLink
                    href="https://soundcloud.com/thomas_rush"
                    target="_blank" // Open in a new tab
                >
                    soundcloud
                </HeaderLink>
                <HeaderLink href="/contact">kapcsolat</HeaderLink>
                <HeaderLink
                    href="https://drive.google.com/drive/u/0/folders/14INyxUa0fv5wcVe56Z9GpQkx0F9oGUEa"
                    target="_blank" // Open in a new tab
                >
                    press
                </HeaderLink>
                <HeaderLink href="/merch">merch</HeaderLink>
            </LinksContainer>
        </Container>
    )
}
