// @ts-nocheck

import React, { useEffect, useRef, useState } from 'react'
import { Alert, Button, Card, Form } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from 'services/auth.service'

export default function Login(): JSX.Element {
    const emailRef = useRef<HTMLInputElement | null>(null)
    const passwordRef = useRef<HTMLInputElement | null>(null)
    const { logIn } = useAuth()
    const [error, setError] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const navigate = useNavigate()

    useEffect(() => {
        let isMounted = true

        return () => {
            if (isMounted) {
                setLoading(false) // Ensure loading state is reset
            }
        }
    }, [])

    async function handleSubmit(e: React.FormEvent): Promise<void> {
        e.preventDefault()

        try {
            setError('')
            setLoading(true)
            await logIn(emailRef.current!.value, passwordRef.current!.value)
            navigate('/admin')
        } catch (error) {
            console.error(error) // Log the error
            setError('Sikertelen bejelentkezés')
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <Card bg="dark" text="white">
                <Card.Body>
                    <h2 className="text-center mb-4">Bejelentkezés</h2>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group id="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" ref={emailRef} required />
                        </Form.Group>
                        <Form.Group id="password">
                            <Form.Label>Jelszó</Form.Label>
                            <Form.Control type="password" ref={passwordRef} required />
                        </Form.Group>
                        <Button disabled={loading} className="w-100 mt-4" type="submit">
                            Bejelentkezés
                        </Button>
                    </Form>
                    <div className="w-100 text-center mt-3 text-white">
                        <Link to="/forgot-password">Elfelejtetted a jelszavad?</Link>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}
