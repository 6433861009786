import styled from 'styled-components'

type BackgroundImageProps = {
    backgroundImage?: string
}

export const BackgroundImage = styled.div<BackgroundImageProps>`
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: #000000;
    z-index: 0;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: ${(props) => props.backgroundImage && `url(${props.backgroundImage})`};
        background-size: cover;
        opacity: 0.2;
    }

    @media screen and (max-width: 480px) {
        background-size: cover;
        background-position: center;
        height: 100%;
        width: 100%;
    }
`
