import styled from 'styled-components'

export const Container = styled.div`
    background-color: #000000;
    color: #ffffff;
    width: 100%;
    min-height: 100vh;
`

export const Wrapper = styled.div`
    display: flex;
    position: relative;
    margin-top: 5rem;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    gap: 70px;

    @media screen and (max-width: 480px) {
        gap: 20px;
    }
`

export const ImageContainer = styled.img`
    position: relative;
    width: 291px;
    height: 438px;
    object-fit: cover;

    @media screen and (max-width: 480px) {
        width: 157px;
        height: 236px;
    }
`
export const TextContainer = styled.div.attrs(() => ({
    className: 'TextContainer',
}))`
    display: flex;
    margin: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 32px;
    width: 488px;
    position: relative;

    @media screen and (max-width: 480px) {
        width: 80%;
    }
`

export const Text = styled.p.attrs(() => ({
    className: 'Text',
}))`
    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    @media screen and (max-width: 480px) {
        font-size: 0.9rem;
    }
`

export const Button = styled.button`
    position: relative;
    width: 200px;
    height: 50px;
    background: #fff;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin: auto;
    align-self: center;
    border: none; /* Remove the default border */
    transform: none; /* Remove any transformations */
    margin-bottom: 3rem;
    margin-top: -3rem;

    @media screen and (max-width: 480px) {
        width: 130px;
        height: 30px;
    }
`

export const ButtonText = styled.p`
    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;

    @media screen and (max-width: 480px) {
        font-size: 18px;
    }
`
