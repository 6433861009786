import React, { useEffect, useState } from 'react'
import { GetAllEvents } from 'services/event.service'
import { autoDeleteEvents, getMonthName } from 'utils'

import { DateText, Event, EventsList, MonthText, PlaceText, Text, TextContainer, YearText } from './Events.style'

interface EventItem {
    _id: string
    date: string
    place: string
    link?: string
}

interface EventsProps {
    notext?: boolean
}

export const Events: React.FC<EventsProps> = (props) => {
    const [events, setEvents] = useState<EventItem[]>([])
    const [eventsSorted, setEventsSorted] = useState<EventItem[]>([])

    const getYearForList = (year: number): JSX.Element | null => {
        return (
            <Event key={`year-${year}`}>
                <YearText>{year}</YearText>
            </Event>
        )
    }

    const getMonthNameForList = (month: number, year: number): JSX.Element | null => {
        return (
            <Event key={`month-${year}-${month}`}>
                <MonthText>{getMonthName(month)}</MonthText>
            </Event>
        )
    }

    const getEvents = () => {
        GetAllEvents().then((res: { data: EventItem[] }) => {
            setEvents(res.data)
        })
    }

    const sortDates = () => {
        const sortedEvents = [...events]
        sortedEvents.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
        autoDeleteEvents(sortedEvents, new Date())
        setEventsSorted(sortedEvents)
    }

    useEffect(() => {
        getEvents()
    }, [])

    useEffect(() => {
        sortDates()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [events])

    const renderedMonths: { [year: number]: number[] } = {}

    return (
        <>
            <EventsList>
                {eventsSorted.map((item) => {
                    let date = new Date(item.date)
                    let newDate: string
                    if (date.getMonth() < 9) {
                        if (date.getDate() < 10) {
                            newDate = `0${date.getMonth() + 1}/0${date.getDate()}`
                        } else {
                            newDate = `0${date.getMonth() + 1}/${date.getDate()}`
                        }
                    } else {
                        if (date.getDate() < 10) {
                            newDate = `${date.getMonth() + 1}/0${date.getDate()}`
                        } else {
                            newDate = `${date.getMonth() + 1}/${date.getDate()}`
                        }
                    }

                    const year = date.getFullYear()
                    const month = date.getMonth()

                    if (!renderedMonths[year]) {
                        renderedMonths[year] = []
                    }

                    if (!renderedMonths[year].includes(month)) {
                        renderedMonths[year].push(month)

                        return (
                            <React.Fragment key={item._id}>
                                {renderedMonths[year].length === 1 && getYearForList(year)}
                                {getMonthNameForList(month, year)}
                                <Event
                                    onClick={() => {
                                        if (item.link !== undefined && item.link !== '') window.open(item.link, '_blank')
                                    }}
                                >
                                    <DateText>{newDate}</DateText>
                                    <PlaceText>{item.place}</PlaceText>
                                </Event>
                            </React.Fragment>
                        )
                    } else {
                        return (
                            <React.Fragment key={item._id}>
                                <Event
                                    onClick={() => {
                                        if (item.link !== undefined && item.link !== '') window.open(item.link, '_blank')
                                    }}
                                >
                                    <DateText>{newDate}</DateText>
                                    <PlaceText>{item.place}</PlaceText>
                                </Event>
                            </React.Fragment>
                        )
                    }
                })}
            </EventsList>
            {props.notext ? (
                ''
            ) : (
                <TextContainer>
                    <Text>Az esemény megtekintéséhez kattints a helyszínre.</Text>
                </TextContainer>
            )}
        </>
    )
}
