import { BackgroundImage } from 'components/BackgroundImage.style'
import { Events } from 'components/Events'
import { Header } from 'components/Header'
import { getRandomBg } from 'utils'

import { Container } from './HomeContent.style'

export const HomeContent = () => {
    return (
        <Container>
            <BackgroundImage backgroundImage={getRandomBg()} />
            <Header />
            <Events />
        </Container>
    )
}
