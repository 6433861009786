import axios from 'axios'

//export const backendUrlBase: string = 'http://localhost:5000'
//export const backendUrlBase: string = 'http://ec2-51-20-95-114.eu-north-1.compute.amazonaws.com/api'
export const backendUrlBase: string = 'https://thomasrush.hu/api'
export const backendUrl: string = `${backendUrlBase}/public`

export const isBrowser = (): boolean => {
    return typeof window !== 'undefined'
}

export const nextLocalStorage = (): Storage | void => {
    if (isBrowser()) {
        return window.localStorage
    }
}

export default axios.create({
    baseURL: `${backendUrlBase}`,
    headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${nextLocalStorage()?.getItem('token')}`,
    },
})
