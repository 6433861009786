// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* index.css */
/* Import Roboto font from Google Fonts */

/* Rest of your CSS */
/* Apply Roboto as the default font for the body */

html {
    font-size: 100%;
}

body {
    font-family: 'Roboto', sans-serif;
}

/* Optionally, you can set Roboto for other elements as well */
h1,
h2,
h3,
p {
    font-family: 'Roboto', sans-serif;
}

/* Rest of your CSS */
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,cAAc;AACd,yCAAyC;;AAGzC,qBAAqB;AACrB,kDAAkD;;AAElD;IACI,eAAe;AACnB;;AAEA;IACI,iCAAiC;AACrC;;AAEA,8DAA8D;AAC9D;;;;IAII,iCAAiC;AACrC;;AAEA,qBAAqB","sourcesContent":["/* index.css */\n/* Import Roboto font from Google Fonts */\n@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');\n\n/* Rest of your CSS */\n/* Apply Roboto as the default font for the body */\n\nhtml {\n    font-size: 100%;\n}\n\nbody {\n    font-family: 'Roboto', sans-serif;\n}\n\n/* Optionally, you can set Roboto for other elements as well */\nh1,\nh2,\nh3,\np {\n    font-family: 'Roboto', sans-serif;\n}\n\n/* Rest of your CSS */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
